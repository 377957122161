
import { Modal } from "@blax-modules/base-front-end/resources/js/ui/modal";
import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load";

export class Video {
    constructor() {
        waitLoad().then(() => {
            let el = document.querySelector('[data-modal-base-path]')
            if (!el)
                return;
            let basePath = el.getAttribute('data-modal-base-path')
            if (!basePath)
                return;
            new Modal({
                basePath: basePath
            });
        })
    }
}