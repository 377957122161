

import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load";

const jsonFeed = [
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/e35/79978384_469126393744088_7916138614893130979_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=77uG78MP8A4AX_McYUp&tp=1&oh=9195fbcb68bb73990d10e2744f86010c&oe=6046C7FD",
        'permalink': "https://www.instagram.com/p/B6VoGVcJIXI/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/e35/75156333_741732342986182_2264469113467299868_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=110&_nc_ohc=h4_eirK-1YkAX_BXwW1&tp=1&oh=4b12f5504fa37b4bee463ba98cf9f7b0&oe=604801DB",
        'permalink': "https://www.instagram.com/p/B6Vn45FJaCQ/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/e35/73063063_149195452981072_8736031418741838934_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=bHCl2pOLT2gAX8Rtm2J&tp=1&oh=03c8732bb3365de509ae86bc7e6e56c1&oe=6045808E",
        'permalink': "https://www.instagram.com/p/B3xPR85JeNm/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/e35/67123092_2365759770413044_7654394680418903781_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=104&_nc_ohc=PZD69A2fivgAX-S_IM9&tp=1&oh=6c4d0e1701a8881a5f42c9302776c7a2&oe=6047FCD8",
        'permalink': "https://www.instagram.com/p/B03UZjyg9r4/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/e35/61115383_341104596471523_5956689439661701732_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=yZPFkCtc0dgAX8ybM4O&tp=1&oh=667beacafd5254ee38ae843c329ae7a2&oe=60475C96",
        'permalink': "https://www.instagram.com/p/ByVLEWNgytK/"
    },
    {
        'caption': "",
        'media_url': "https://instagram.fymq2-1.fna.fbcdn.net/v/t51.2885-15/e35/61602237_745293382535358_7111779724287557621_n.jpg?_nc_ht=instagram.fymq2-1.fna.fbcdn.net&_nc_cat=106&_nc_ohc=bm6DO7OBwmIAX-xo8lG&tp=1&oh=9e8956f5f11ea500c6b36a236bd9861d&oe=60473392",
        'permalink': "https://www.instagram.com/p/ByVKyOiAO8A/"
    }
]

const DEFAULT_PARAMS = {
    accessToken: instagram_token,
    feedContainerSelector: '.bl-instafeed',
    itemToshow: 6,
    url: 'https://graph.instagram.com/me/media?fields=',
    urlParams: ['caption', 'media_url', 'permalink']
};

export class InstagramFeed {
    constructor(params) {
        this.params = Object.assign({}, DEFAULT_PARAMS, params)
        waitLoad().then(() => {
            if(typeof instagram_token !== 'undefined' && instagram_token.length > 0){
                this.initInstagramFeed();
                //this.getPublicFeed();
                //this.getFeedStatic();
            }
        })
    }

    initInstagramFeed() {
        this.feedContainer = document.querySelector(this.params.feedContainerSelector);
        if (!this.feedContainer)
            return;
        this.accessToken = '&access_token=' + this.params.accessToken;
        if (this.feedContainer.getAttribute('instragram-access-token'))
            this.accessToken = '&access_token=' + this.feedContainer.getAttribute('instragram-access-token');
        this.urlParams = this.params.urlParams;
        this.url = this.params.url
        this.itemToShow = this.params.itemToshow

        const itemNb = 6;

        let urlString = this.url + this.urlParams.join() + this.accessToken
        this.getFeed(urlString);
    }

    getFeed(urlString) {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', urlString, true)
        xhr.send();

        xhr.onload = processRequest
        let container = this.feedContainer
        function processRequest(e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
                container.innerHTML = ''
                var response = JSON.parse(xhr.responseText)
                let dataArr = Object.values(response.data)
                console.log(response.data)
                dataArr.slice(0, 6).forEach(element => {
                    container.innerHTML += `<div><a href="${element.permalink}" target="_blank" rel="noopener"><img src="${element.media_url}" alt="${element.caption}"></a></div>`
                });
            }
        }
    }

    getFeedStatic() {
        if (!this.feedContainer) return
        let container = this.feedContainer
        let dataArr = Object.values(jsonFeed)
        dataArr.slice(0, 6).forEach(element => {
            container.innerHTML += `<div><a href="${element.permalink}" target="_blank" rel="noopener"><img src="${element.media_url}" alt="${element.caption}"></a></div>`
        });
    }

    getPublicFeed() {
        if (!this.feedContainer) return
        var xhr = new XMLHttpRequest();
        xhr.open('GET', 'https://www.instagram.com/fortinautoradio/?__a=1', true)
        xhr.send();

        xhr.onload = processRequest
        let container = this.feedContainer
        function processRequest(e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
                container.innerHTML = ''
                var response = JSON.parse(xhr.responseText)
                let dataArr = Object.values(response.graphql.user.edge_owner_to_timeline_media.edges)
                dataArr.slice(0, 6).forEach(element => {
                    container.innerHTML += `<div><a href="https://www.instagram.com/p/${element.node.shortcode}" target="_blank" rel="noopener"><img src="${element.node.thumbnail_src}" alt="${element.node.accessibility_caption}"></a></div>`
                });
            }
        }
    }

}