import { waitLoad } from "@blax-modules/base-front-end/resources/js/utilities/load";

export class Site_users {
    constructor() {
        waitLoad().then(() => {
            this.init();
        })
    }

    init() {
        let client_selector = document.querySelector('#client_id');

        let selected_client_id = document.querySelector('#selected_client_id');

        if(typeof selected_client_id !== 'undefined' && selected_client_id !== null){
            selected_client_id.addEventListener('change', function (e) {
                let current_element = e.target;

                if (client_selector.value > 0) {
                    document.querySelector('#name').value = client_selector.options[client_selector.selectedIndex].getAttribute('data-name');
                    document.querySelector('#email').value = client_selector.options[client_selector.selectedIndex].getAttribute('data-email');
                    document.querySelector('#phone').value = client_selector.options[client_selector.selectedIndex].getAttribute('data-phone');
                }
            });
        }

        let star_rating = document.querySelectorAll('.rating input');

        if(typeof star_rating !== 'undefined' && star_rating !== null){
            for (var i = 0; i < star_rating.length; i++) {
                star_rating[i].parentNode.classList.remove('checked');
                star_rating[i].addEventListener('click', function(e){
                    adjust_rating(e);
                });
            }
        }

        function adjust_rating(e)
        {
            let current_group = e.currentTarget.parentNode.parentNode.children;
            for (var i = 0; i < current_group.length; i++) {
                current_group[i].classList.remove('checked');
            }

            e.currentTarget.parentNode.classList.add("checked");
        }
    }
}
