import {setCookie, storageAvailable, getCookie, cleanCookieName} from '../utilities/cookies'
import {waitLoad} from "../utilities/load";

var DEFAULT_OPTIONS = {
    occurence: 'eternity' //eternity, session, page
}

//changer popup pour message
//mettre les variables dans le css

export class BindMessage {
    constructor(name, options) {
        options = Object.assign({}, DEFAULT_OPTIONS, options);
        let popups = document.querySelectorAll('.' + name + '-message');

        for (let i in popups) {
            if (!popups.hasOwnProperty(i))
                continue;
            let popup = popups[i],
                popupTimeout,
                popupOnTop;
            popupTimeout = (popup.matches('[data-' + name + '-timeout]')) ? parseInt(popup.getAttribute('data-' + name + '-timeout')) : 4000;

            popupOnTop = popup.matches('[data-' + name + '-top]');
            if (popupOnTop) {
                document.body.classList.add('will-have-' + name + '-message')
            }

            let closeBtns = document.querySelectorAll('.' + name + '-close');
            for (let x in closeBtns) {
                if(closeBtns[x] && closeBtns[x] instanceof Element || closeBtns[x] && closeBtns[x] instanceof HTMLDocument) {
                    var togglePopup = function (val, index) {
                        if(index)
                            popups[index].classList.toggle('visible', val);
                        else
                            popups[x].classList.toggle('visible', val);
                        if (popupOnTop) {
                            document.body.classList.toggle('has-' + name + '-message', val);
                        }
                    }
                    var wasAccepted;
                    if (options.occurence === 'eternity' && storageAvailable('localStorage'))
                        wasAccepted = !!localStorage.getItem(name + '-accepted');
                    else
                        wasAccepted = !!getCookie(cleanCookieName(name));
                    if (!wasAccepted) {
                        togglePopup(true);
                    }

                    let closeBtn = closeBtns[x];
                    closeBtn.addEventListener('click', function (e) {
                        togglePopup(false, x);
                        switch (options.occurence) {
                            case 'page':
                                break;
                            case 'session':
                                setCookie(cleanCookieName(name), '1', 0);
                                break;
                            default:
                                if (storageAvailable('localStorage'))
                                    localStorage.setItem(name + '-accepted', '1');
                                else
                                    setCookie(cleanCookieName(name), '1', 10000)
                                break;
                        }
                    });
                }

            }
        }
    }
}
